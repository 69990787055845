import _extends from "/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/extends.js";
import _objectWithoutProperties from "/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/objectWithoutProperties.js";
var _excluded = ["appendDots", "Settings", "speed", "fade", "cssEase", "children", "className", "inheritedStyles", "accessibility", "dots", "infinite", "autoplay", "autoplaySpeed", "slidesToShow", "slidesToScroll", "pauseOnDotsHover", "pauseOnFocus", "pauseOnHover", "arrows", "initialSlide", "prevArrow", "nextArrow", "height", "mobileHeroVariation", "displayPlayPauseButton"];
var __jsx = React.createElement;
import React, { Fragment, useEffect, useRef, useState } from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import styled from 'styled-components';
import styles from './Carousel.style';
import CarouselPausePlayToggle from '../../atoms/CarouselPausePlayToggle';
var Carousel = function Carousel(_ref) {
  var appendDots = _ref.appendDots,
    Settings = _ref.Settings,
    speed = _ref.speed,
    fade = _ref.fade,
    cssEase = _ref.cssEase,
    children = _ref.children,
    className = _ref.className,
    inheritedStyles = _ref.inheritedStyles,
    accessibility = _ref.accessibility,
    dots = _ref.dots,
    infinite = _ref.infinite,
    autoplay = _ref.autoplay,
    autoplaySpeed = _ref.autoplaySpeed,
    slidesToShow = _ref.slidesToShow,
    slidesToScroll = _ref.slidesToScroll,
    pauseOnDotsHover = _ref.pauseOnDotsHover,
    pauseOnFocus = _ref.pauseOnFocus,
    pauseOnHover = _ref.pauseOnHover,
    arrows = _ref.arrows,
    initialSlide = _ref.initialSlide,
    prevArrow = _ref.prevArrow,
    nextArrow = _ref.nextArrow,
    height = _ref.height,
    mobileHeroVariation = _ref.mobileHeroVariation,
    displayPlayPauseButton = _ref.displayPlayPauseButton,
    otherProps = _objectWithoutProperties(_ref, _excluded);
  var setCurrentCount = otherProps.setCurrentCount;
  var _useState = useState(null),
    isActiveSlide = _useState[0],
    setIsActiveSlide = _useState[1];
  var slider = useRef(null);
  var _useState2 = useState(autoplay),
    isPlaying = _useState2[0],
    setIsPlaying = _useState2[1];
  var multipleSlides = (children === null || children === void 0 ? void 0 : children.length) > 1;
  var _useState3 = useState(null),
    prefersReducedMotion = _useState3[0],
    setPrefersReducedMotion = _useState3[1];
  useEffect(function () {
    if (typeof window !== 'undefined') {
      var _window;
      var mediaQuery = (_window = window) === null || _window === void 0 ? void 0 : _window.matchMedia("(prefers-reduced-motion: reduce)");
      setPrefersReducedMotion(mediaQuery === null || mediaQuery === void 0 ? void 0 : mediaQuery.matches);
      setIsPlaying(mediaQuery !== null && mediaQuery !== void 0 && mediaQuery.matches ? false : autoplay);
    }
  }, []);
  var onPauseSliderToggle = function onPauseSliderToggle() {
    // `current` points to the mounted text input element
    if (isPlaying) {
      slider.current.slickPause();
      setIsPlaying(false);
    } else {
      slider.current.slickPlay();
      setIsPlaying(true);
    }
  };
  return __jsx(Fragment, null, __jsx("div", {
    className: "nva-carousel ".concat(className, " showCarouselContainer"),
    "aria-roledescription": "carousel",
    role: 'region',
    "aria-label": 'Carousel',
    "aria-live": 'off'
  }, __jsx(Slider, _extends({
    ref: slider,
    appendDots: function appendDots(dots) {
      return __jsx("ul", null, dots);
    },
    speed: speed,
    fade: fade,
    cssEase: cssEase,
    className: "nva-carousel__slider ".concat(className),
    accessibility: true,
    autoplaySpeed: autoplaySpeed,
    autoplay: prefersReducedMotion ? false : autoplay,
    arrows: !fade,
    Settings: true,
    dots: dots,
    infinite: true,
    pauseOnDotsHover: true,
    pauseOnFocus: true,
    slidesToScroll: true,
    slidesToShow: slidesToShow,
    initialSlide: initialSlide,
    afterChange: function afterChange(i) {
      setCurrentCount && setCurrentCount(null, i);
      setIsActiveSlide(i);
    },
    prevArrow: prevArrow,
    nextArrow: nextArrow,
    customPaging: function customPaging(i) {
      return __jsx("button", {
        tabIndex: 0,
        "aria-description": "slide ".concat(i + 1),
        className: "nva-carousel__slider-dots slick-dots-custom",
        "aria-label": "Slide ".concat(i + 1)
      });
    }
  }, otherProps), children), displayPlayPauseButton && multipleSlides && __jsx(CarouselPausePlayToggle, {
    className: "nva-carousel__pause-play-toggle",
    onPauseSliderToggle: onPauseSliderToggle,
    isPlaying: isPlaying
  })), __jsx("div", {
    className: "nva-carousel__all-tiles-container ".concat(className, " showAllTilesContainer")
  }, children));
};
Carousel.defaultProps = {
  speed: 1000,
  fade: false,
  cssEase: 'ease-in-out',
  className: '',
  inheritedStyles: '',
  accessibility: true,
  dots: true,
  infinite: true,
  autoplay: false,
  autoplaySpeed: 1000,
  slidesToShow: 5,
  slidesToScroll: 1,
  arrows: false,
  styleType: '',
  pauseOnDotsHover: true,
  pauseOnFocus: true,
  pauseOnHover: true,
  initialSlide: 0
};
export default styled(Carousel).withConfig({
  componentId: "sc-10lpcsk-0"
})(["", ";"], styles);
export { Carousel as CarouselVanilla };