export var galleryModalImageRenditions = {
  desktopL: {
    w: '982',
    h: '693'
  },
  desktop: {
    w: '982',
    h: '654'
  },
  tabletL: {
    w: '876',
    h: '586'
  },
  tabletP: {
    w: '508',
    h: '340'
  },
  mobile: {
    w: '248',
    h: '166'
  }
};