export var heroMobileFullVariation = {
  large: 500,
  medium: 400,
  small: 300
};
export var heroFixedHeights = function heroFixedHeights(height, mobileHeroVariation) {
  return {
    desktopL: {
      h: 567
    },
    desktop: {
      h: 567
    },
    tabletL: {
      h: 420
    },
    tabletP: {
      h: mobileHeroVariation === 'split' ? 420 : heroMobileFullVariation[height]
    },
    mobile: {
      h: mobileHeroVariation === 'split' ? 250 : heroMobileFullVariation[height]
    }
  };
};
export var imageRenditions = function imageRenditions(height, mobileHeroVariation) {
  return {
    desktopL: {
      h: 567,
      w: 1600
    },
    desktop: {
      h: 567,
      w: 1366
    },
    tabletL: {
      h: 420,
      w: 768
    },
    tabletP: {
      h: mobileHeroVariation === 'split' ? 420 : heroMobileFullVariation[height],
      w: 768
    },
    mobile: {
      h: mobileHeroVariation === 'split' ? 250 : heroMobileFullVariation[height],
      w: 320
    }
  };
};
export var imageRenditionsVariationC = function imageRenditionsVariationC(height, mobileHeroVariation) {
  return {
    desktopL: {
      h: 567,
      w: 880
    },
    desktop: {
      h: 567,
      w: 752
    },
    tabletL: {
      h: 420,
      w: 564
    },
    tabletP: {
      h: mobileHeroVariation === 'split' ? 420 : heroMobileFullVariation[height],
      w: 768
    },
    mobile: {
      h: mobileHeroVariation === 'split' ? 250 : heroMobileFullVariation[height],
      w: 320
    }
  };
};
export var heroMediumAndSmallDesktopFixedHeights = {
  medium: 405,
  small: 289
};