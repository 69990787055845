var __jsx = React.createElement;
import React from 'react';
import { withTheme } from 'styled-components';
import DefaultCarouselPlay from '../../../static/images/icons/CarouselPlay';
import DefaultCarouselPause from '../../../static/images/icons/CarouselPause';
import styled from 'styled-components';
import styles from './CarouselPausePlayToggle.style';
var StyleSection = styled.section.withConfig({
  componentId: "sc-2c8ryd-0"
})(["", ";"], styles);
var CarouselPausePlayToggle = function CarouselPausePlayToggle(_ref) {
  var isPlaying = _ref.isPlaying,
    onPauseSliderToggle = _ref.onPauseSliderToggle,
    theme = _ref.theme,
    className = _ref.className,
    style = _ref.style;
  var Play = theme !== null && theme !== void 0 && theme.carousel.iconPlay ? theme.carousel.iconPlay : DefaultCarouselPlay;
  var Pause = theme !== null && theme !== void 0 && theme.carousel.iconPause ? theme.carousel.iconPause : DefaultCarouselPause;
  return __jsx(StyleSection, {
    className: "nva-carousel-pause-toggle ".concat(className)
  }, __jsx("button", {
    className: "carousel-pause-toggle-container",
    onClick: onPauseSliderToggle,
    title: isPlaying ? 'pause' : 'play',
    "aria-label": isPlaying ? 'carousel pause' : 'carousel play',
    tabIndex: 0
  }, __jsx("span", {
    className: "nva-carousel-pause-toggle__button carousel-pause-toggle"
    // currentSlide={currentSlide}
    // slideCount={slideCount}
    // onClick={onClick}
    // data-analytics-type={analyticsType}
    // data-analytics-value={analyticsValue}
    // data-analytics-variation=""
    // aria-label={type === 'previous' ? 'previous' : 'next'}
    // styleType="primary"
    // type="submit"
    ,
    "aria-hidden": "true"
  }, isPlaying ? __jsx(Pause, null) : __jsx(Play, null))));
};
CarouselPausePlayToggle.defaultProps = {
  className: '',
  analyticsType: '',
  analyticsValue: ''
};
var styledCarouselPausePlayToggle = styled(CarouselPausePlayToggle).withConfig({
  componentId: "sc-2c8ryd-1"
})(["", ";"], styles);
export default withTheme(styledCarouselPausePlayToggle);
export { CarouselPausePlayToggle as CarouselPausePlayToggleVanilla };