var __jsx = React.createElement;
import * as React from 'react';
function CarouselPause() {
  return __jsx("svg", {
    version: "1.1",
    id: "Capa_1",
    xmlns: "http://www.w3.org/2000/svg",
    xmlnsXlink: "http://www.w3.org/1999/xlink",
    x: "0px",
    y: "0px",
    viewBox: "0 0 32 32",
    style: {
      enableBackground: 'new 0 0 17.804 17.804',
      fill: '#fff'
    },
    xmlSpace: "preserve"
  }, __jsx("g", null, __jsx("g", {
    id: "pause"
  }, __jsx("g", null, __jsx("rect", {
    x: "4",
    width: "8",
    height: "32"
  }), __jsx("rect", {
    x: "20",
    width: "8",
    height: "32"
  })))));
}
export default CarouselPause;