import _objectWithoutProperties from "/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/objectWithoutProperties.js";
var _excluded = ["className", "ariaLabel", "review", "reviewLinkUrl", "reviewLinkHyperlinkType", "reviewLinkText", "reviewerImageUrl", "reviewerImageAlt", "reviewerImageTitle", "reviewerName", "dataAnalyticsType", "dataAnalyticsValue", "srcSetSetting"];
var __jsx = React.createElement;
import React from 'react';
import styled from 'styled-components';
import styles from './ReviewCard.style';
import Para from '../../atoms/Para';
import RichText from '../../atoms/RichText/RichText';
import Anchor from '../../atoms/Anchor';
import Image from '../../atoms/Image';
import { linkTargetWindow } from '../../../constants/globalConstants';
var ReviewCardBlock = styled.section.withConfig({
  componentId: "sc-b2f152-0"
})(["", ";"], styles);
var ReviewCard = function ReviewCard(_ref) {
  var className = _ref.className,
    ariaLabel = _ref.ariaLabel,
    review = _ref.review,
    reviewLinkUrl = _ref.reviewLinkUrl,
    reviewLinkHyperlinkType = _ref.reviewLinkHyperlinkType,
    reviewLinkText = _ref.reviewLinkText,
    reviewerImageUrl = _ref.reviewerImageUrl,
    reviewerImageAlt = _ref.reviewerImageAlt,
    reviewerImageTitle = _ref.reviewerImageTitle,
    reviewerName = _ref.reviewerName,
    dataAnalyticsType = _ref.dataAnalyticsType,
    dataAnalyticsValue = _ref.dataAnalyticsValue,
    srcSetSetting = _ref.srcSetSetting,
    others = _objectWithoutProperties(_ref, _excluded);
  return __jsx(ReviewCardBlock, {
    className: "nva-review-card"
  }, __jsx("svg", {
    x: "0px",
    y: "0px",
    viewBox: "0 0 45 32"
  }, __jsx("g", {
    transform: "translate(-9.001 -16.002)"
  }, __jsx("path", {
    d: "M19,27.7c-1.4,0-2.7,0.3-4,0.8c1.7-3.8,4.3-7.1,7.4-8.8c1-0.5,1.3-1.8,0.8-2.7 c-0.5-1-1.7-1.3-2.7-0.8c0,0,0,0,0,0C13.8,19.9,9,29,9,37.9c0.1,5.5,4.6,9.9,10.1,9.9c5.5-0.1,9.9-4.6,9.9-10.1 C28.9,32.1,24.5,27.7,19,27.7z"
  }), __jsx("path", {
    d: "M44,27.7c-1.4,0-2.7,0.3-4,0.8c1.7-3.8,4.3-7.1,7.4-8.8c1-0.5,1.3-1.8,0.8-2.7 c-0.5-1-1.7-1.3-2.7-0.8c0,0,0,0,0,0C38.8,19.9,34,29,34,37.9c0.1,5.5,4.6,9.9,10.1,9.9c5.5-0.1,9.9-4.6,9.9-10.1 C53.9,32.1,49.5,27.7,44,27.7z"
  }))), __jsx(RichText, {
    jsonContent: review,
    className: "nva-review-card__review-body heading_four"
  }), reviewLinkUrl && __jsx(Para, null, __jsx(Anchor, {
    className: "nva-review-card__anchor",
    title: reviewLinkText,
    to: reviewLinkUrl,
    hyperlinkType: reviewLinkHyperlinkType,
    "aria-label": ariaLabel,
    "data-analytics-type": dataAnalyticsType,
    "data-analytics-value": dataAnalyticsValue,
    ctaBehavior: linkTargetWindow.newWindow
  }, reviewLinkText)), __jsx("div", {
    className: "nva-review-card__reviewer reviewer-container"
  }, __jsx(Image, {
    className: "nva-review-card__reviewer-image",
    src: reviewerImageUrl,
    alt: reviewerImageAlt,
    title: reviewerImageTitle,
    srcSetSettingImg: srcSetSetting
  }), __jsx(Para, {
    className: "nva-review-card__reviewer-name heading_five"
  }, reviewerName)));
};
ReviewCard.defaultProps = {
  className: '',
  ariaLabel: '',
  review: '',
  reviewLinkUrl: '',
  reviewLinkHyperlinkType: '',
  reviewLinkText: '',
  reviewerImageUrl: '',
  reviewerName: ''
};
export default ReviewCard;