import { css } from 'styled-components';
import breakPoints from '../../../themes/breakpoints';
var ReviewCardStyles = css(["background:var(--sc-review-card--card-bg-color,", ");box-sizing:border-box;text-align:left;position:relative;border-radius:var(--sc-review-card--border-radius,", ");svg{width:45px;height:32px;fill:var(--sc-review-card--link-color,", ");}p{margin:var(--sc-spacing-4,20px) 0 var(--sc-spacing-3,10px);@media (min-width:", "){margin:var(--sc-spacing-5,30px) 0 var(--sc-spacing-3,10px);}}a{color:var(--sc-review-card--link-color,", ");font-size:inherit;letter-spacing:inherit;line-height:inherit;&:hover{text-decoration:underline;color:var(--sc-review-card--link-color-hover,", ");}}.reviewer-container{margin-top:auto;width:76px;overflow:hidden;display:flex;flex-direction:column;padding-top:var(--sc-review-card--reviewer-container-padding-top_mobile,", ");@media (max-width:", "){", "}@media (min-width:", "){padding-top:var(--sc-spacing-8,50px);}}.heading_five{margin:0;padding-top:var(--sc-spacing-3,10px);color:var(--sc-review-card--text-color,", ");}@media (max-width:", "){padding:var(--sc-spacing-4,20px);margin-left:auto;margin-right:auto;width:var(--sc-review-card--card-width_mobile,", ");", " &:not(:last-of-type){margin-bottom:var(--sc-review-card--container-margin-bottom,", ");}}@media (min-width:", "){padding:var(--sc-spacing-5,30px);display:flex;flex-direction:column;vertical-align:middle;width:calc(33.33% - 10px);max-width:308px;&:not(:last-of-type){margin-right:var(--sc-review-card--container-margin-right,", ");}}@media (min-width:", "){padding:var(--sc-spacing-6,30px);display:flex;flex-direction:column;vertical-align:middle;width:calc(33.33% - 10px);max-width:337px;&:not(:last-of-type){margin-right:var(--sc-review-card--container-margin-right,", ");}}"], function (props) {
  var _props$theme, _props$theme$reviewCa;
  return (_props$theme = props.theme) === null || _props$theme === void 0 ? void 0 : (_props$theme$reviewCa = _props$theme.reviewCard) === null || _props$theme$reviewCa === void 0 ? void 0 : _props$theme$reviewCa.cardBgColor;
}, function (props) {
  var _props$theme2, _props$theme2$reviewC;
  return (_props$theme2 = props.theme) === null || _props$theme2 === void 0 ? void 0 : (_props$theme2$reviewC = _props$theme2.reviewCard) === null || _props$theme2$reviewC === void 0 ? void 0 : _props$theme2$reviewC.borderRadius;
}, function (props) {
  var _props$theme3, _props$theme3$reviewC;
  return (_props$theme3 = props.theme) === null || _props$theme3 === void 0 ? void 0 : (_props$theme3$reviewC = _props$theme3.reviewCard) === null || _props$theme3$reviewC === void 0 ? void 0 : _props$theme3$reviewC.reviewLinkColor;
}, breakPoints.desktop, function (props) {
  var _props$theme4, _props$theme4$reviewC;
  return (_props$theme4 = props.theme) === null || _props$theme4 === void 0 ? void 0 : (_props$theme4$reviewC = _props$theme4.reviewCard) === null || _props$theme4$reviewC === void 0 ? void 0 : _props$theme4$reviewC.reviewLinkColor;
}, function (props) {
  var _props$theme5, _props$theme5$reviewC;
  return (_props$theme5 = props.theme) === null || _props$theme5 === void 0 ? void 0 : (_props$theme5$reviewC = _props$theme5.reviewCard) === null || _props$theme5$reviewC === void 0 ? void 0 : _props$theme5$reviewC.reviewLinkHoverColor;
}, function (props) {
  var _props$theme6, _props$theme6$reviewC;
  return ((_props$theme6 = props.theme) === null || _props$theme6 === void 0 ? void 0 : (_props$theme6$reviewC = _props$theme6.reviewCard) === null || _props$theme6$reviewC === void 0 ? void 0 : _props$theme6$reviewC.reviewerContainerMobileTopPadding) || "80px";
}, breakPoints.mobile, function (props) {
  var _props$theme7, _props$theme7$reviewC;
  return (_props$theme7 = props.theme) !== null && _props$theme7 !== void 0 && (_props$theme7$reviewC = _props$theme7.reviewCard) !== null && _props$theme7$reviewC !== void 0 && _props$theme7$reviewC.hideReviewerPicture ? "picture {\n        display: none;\n      }" : '';
}, breakPoints.desktop, function (props) {
  var _props$theme8, _props$theme8$reviewC;
  return (_props$theme8 = props.theme) === null || _props$theme8 === void 0 ? void 0 : (_props$theme8$reviewC = _props$theme8.reviewCard) === null || _props$theme8$reviewC === void 0 ? void 0 : _props$theme8$reviewC.reviewTextColor;
}, breakPoints.tabletL, function (props) {
  var _props$theme9, _props$theme9$reviewC;
  return ((_props$theme9 = props.theme) === null || _props$theme9 === void 0 ? void 0 : (_props$theme9$reviewC = _props$theme9.reviewCard) === null || _props$theme9$reviewC === void 0 ? void 0 : _props$theme9$reviewC.tabletCardWidth) || "280px";
}, function (props) {
  var _props$theme10, _props$theme10$review, _props$theme11, _props$theme11$review;
  return (_props$theme10 = props.theme) !== null && _props$theme10 !== void 0 && (_props$theme10$review = _props$theme10.reviewCard) !== null && _props$theme10$review !== void 0 && _props$theme10$review.mobileMaxWidth ? "max-width: var(--sc-review-card--max-width_mobile, ".concat((_props$theme11 = props.theme) === null || _props$theme11 === void 0 ? void 0 : (_props$theme11$review = _props$theme11.reviewCard) === null || _props$theme11$review === void 0 ? void 0 : _props$theme11$review.mobileMaxWidth, ");") : '';
}, function (props) {
  var _props$theme12, _props$theme12$review;
  return ((_props$theme12 = props.theme) === null || _props$theme12 === void 0 ? void 0 : (_props$theme12$review = _props$theme12.reviewCard) === null || _props$theme12$review === void 0 ? void 0 : _props$theme12$review.containerMarginBottom) || "var(--sc-spacing-3, 10px)";
}, breakPoints.desktop, function (props) {
  var _props$theme13, _props$theme13$review;
  return ((_props$theme13 = props.theme) === null || _props$theme13 === void 0 ? void 0 : (_props$theme13$review = _props$theme13.reviewCard) === null || _props$theme13$review === void 0 ? void 0 : _props$theme13$review.containerMarginRight) || "var(--sc-spacing-4, 15px)";
}, breakPoints.desktopM, function (props) {
  var _props$theme14, _props$theme14$review;
  return ((_props$theme14 = props.theme) === null || _props$theme14 === void 0 ? void 0 : (_props$theme14$review = _props$theme14.reviewCard) === null || _props$theme14$review === void 0 ? void 0 : _props$theme14$review.containerMarginRight) || "var(--sc-spacing-4, 15px)";
});
export default ReviewCardStyles;